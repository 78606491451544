<template>
    <ClientSkeleton v-if="isLoading" class="px-6 pb-6 pt-5" />
    <div class="container is-fluid pt-5 pb-6 px-6 min-h-90vh" v-else>
      <div class="is-flex is-justify-content-space-between mb-5">
        <div class="is-flex is-align-items-center is-clickable" @click="goBack()">
          <ArrowLeftIcon class="mr-3" />
          <p class="averta-bold is-size-5">
             {{ isSMRC ? listStore.branch.name : 'Cabang ' + listStore.branch.name }}
          </p>
        </div>
        <div class="flex-center-vertical">
          <b-field class="mb-0 input-custom-1" v-if="tabActive === 'list'">
            <b-input
              :placeholder="`Cari nama ${renderTitleStore}`"
              type="search"
              icon="magnify"
              @icon-click="search = ''"
              @keyup.native.enter="getListStore({dontLoading: true})"
              v-model="search"
            >
            </b-input>
          </b-field>
          <b-field class="mb-0 input-custom-1" v-if="tabActive === 'pic'">
            <b-input
              :placeholder="`Cari nama ${renderTitlePICBranch}`"
              type="search"
              icon="magnify"
              @icon-click="searchPic = ''"
              @keyup.native.enter="filterPic"
              v-model="searchPic"
            >
            </b-input>
          </b-field>
          <ButtonApp @click="handleAddStore" class="ml-5" v-if="tabActive === 'list'">
            <p class="averta-bold color-white py-2 px-5">{{ isSMRC ? "+ Tambah Building" : '+ Tambah Toko'  }}</p>
          </ButtonApp>
          <ButtonApp @click="handleAddPicBranch" class="ml-5" v-if="tabActive === 'pic'">
            <p class="averta-bold color-white py-2 px-5">{{ isSMRC ? "+ Chief Engineering" : '+ Tambah PIC'  }}</p>
          </ButtonApp>
        </div>
      </div>

       <div v-if="isSMRC" class="columns">
         <div class="column card col-1 p-3 m-2" v-for="(store, index) in getListDaftarStoreData" :key="index">
          <img :src="require(`@/assets/img/${store.image}`)"  alt="wave" class="wave" />
          <p class="is-size-5 averta-bold">{{ store.total }}</p>
          <p class="is-size-4 averta-black my-2">{{ store.listStore }}</p>
        </div>
       </div>

       <!-- SMRCN -->
        <div style="gap: 1rem" v-if="isSMRC" class="notification is-flex is-light border-radius-20 border-2 c-w-100">
          <img src="@/assets/img/circelcard.svg"  alt="wave" class="wave-saldo" />
          <div class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start">
            <SaldoCoinIcon size="50" />
          </div>
          <div>
            <p class="label-14 color-grey">Saldo SPK</p>
            <p class="label-28 averta-black">
              {{ listStore.branch.balance === null ? 'Rp0' : formatCurrency(listStore.branch.balance) }}
            </p>
            <p class="label-16 color-grey averta-regular-italic">Terakhir Update: {{formatDate(listStore.branch.balance_updated_at)}}</p>
          </div>
        </div>
        <!-- SMRCN -->

      <div class="flex-center-vertical mb-5">
        <div class="btn-tabs-2 mr-3" @click="handleClickTab('list')" :class="{'is-active': tabActive === 'list'}">
          <p>Daftar {{ renderTitleStore }}</p>
        </div>
        <div class="btn-tabs-2 mr-3" @click="handleClickTab('pic')" :class="{'is-active': tabActive === 'pic'}">
          <p>Daftar {{ renderTitlePICBranch }}</p>
        </div>
        <div v-if="isSMRC" class="btn-tabs-2 mr-3" @click="handleClickTab('spk')" :class="{'is-active': tabActive === 'spk'}">
          <p>Saldo & Surat Perintah Kerja (SPK)</p>
        </div>
        <div v-if="isSMRC" class="btn-tabs-2" @click="handleClickTab('sik')" :class="{'is-active': tabActive === 'sik'}">
          <p>Surat Izin Kerja (SIK)</p>
        </div>
      </div>

      <TabListStore
        v-if="tabActive === 'list'"
        :listStore="listStore"
        :refreshData="getListStore"
        @edit="editStore($event)"
      />

      <TabPICBranch
        v-if="tabActive === 'pic'"
        :listStore="listStore"
        :dataPic="dataPicFilter"
        :dataChief="dataChief"
        :refreshData="getListStore"
      />

      <TabListSPK
        v-if="tabActive === 'spk'"
        :dataSPK="listStore.spk"
      />

      <TabListSIK
        v-if="tabActive === 'sik'"
        :dataSIK="listStore.sik"
      />

      <SidebarStore
        :show="showSidebar"
        :close="handleCloseSidebar"
        :showTabButton="showTabButton"
        @refreshData="getListStore"
      />

      <SidebarAddPICBranch
        :show="sidebarAddPIC"
        :close="handleCloseSidebar"
        @reloadData="getListStore($event)"
      />
    </div>
</template>

<script>
// Helpers
import { mapGetters } from 'vuex'
import { alertErrorMessage, titleStore, titlePICBranch, formatCurrency, formatDateHelper } from '@/helpers'
import { SUMMARECON_MTOWN } from '@/helpers/constants'
import { ListDaftarStoreData } from '@/data/views/liststoreData'

// Icons
import { ArrowLeftIcon, SaldoCoinIcon } from '@/components/icons'

// Components
import ClientSkeleton from '@/components/skeleton/ClientSkeleton.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import TabPICBranch from './components/store-components/TabPICBranch.vue'
import TabListStore from './components/store-components/TabListStore.vue'
import SidebarStore from './components/SidebarStore.vue'
import SidebarAddPICBranch from './components/branch-components/SidebarAddPICBranch.vue'
import TabListSPK from './components/store-components/TabListSPK.vue'
import TabListSIK from './components/store-components/TabListSIK.vue'

export default {
  name: 'ListStore',
  components: {
    // Icons
    ArrowLeftIcon,
    SaldoCoinIcon,
    // Components
    ClientSkeleton,
    // eslint-disable-next-line vue/no-unused-components
    ButtonApp,
    TabListStore,
    TabPICBranch,
    SidebarStore,
    SidebarAddPICBranch,
    TabListSPK,
    TabListSIK
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    isSMRC () {
      return this.listStore && this.listStore.vendor.code === SUMMARECON_MTOWN
    },
    getListDaftarStoreData () {
      return ListDaftarStoreData(this.listStore)
    },
    renderTitleStore () {
      return titleStore(this.listStore.vendor.code)
    },
    renderTitlePICBranch () {
      return titlePICBranch(this.listStore.vendor.code)
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingChief: false,
      listStore: null,
      initListStore: null,
      tabActive: 'list', // list, pic, spk, sik
      showSidebar: false,
      showTabButton: true,
      sidebarAddPIC: false,
      search: '',
      searchPic: '',
      dataPicFilter: null,
      dataChief: [],
      initChief: []
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    editStore (data) {
      this.$store.commit('client/setTypeSidebarStore', 'edit-store')
      this.$store.commit('client/setPayloadStore', { formStore: data })
      this.showSidebar = true
      this.showTabButton = false
    },
    getListStore (props) {
      if (props && props.refresh) {
        this.getChief()
      }

      if (!props || !props.dontLoading) {
        this.isLoading = true
      }
      const payload = {
        url: this.url,
        branch_id: this.$route.params.branchId,
        search: this.search
      }

      this.$store
        .dispatch('client/getListStore', payload)
        .then(response => {
          this.listStore = response.data.data
          if (!this.initListStore || (props && props.refresh)) {
            this.initListStore = JSON.parse(JSON.stringify(this.listStore))
          }
          if (!this.dataPicFilter || (props && props.refresh)) {
            this.dataPicFilter = {
              branch: response.data.data.branch,
              pics: response.data.data.pics
            }
          }

          this.$store.commit('client/setDetailClient', { data: response.data.data.vendor })
          this.$store.commit('client/setDetailBranch', { data: response.data.data.branch })
          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'branchSelected',
            branchSelected: response.data.data.branch
          })

          this.$store.commit('client/setPayloadAddPicBranch', {
            type: 'clientSelected',
            clientSelected: response.data.data.vendor
          })

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    filterPic () {
      const filter = this.initChief.filter(pic => {
        return pic.name
          .toLowerCase()
          .includes(this.searchPic.toLowerCase())
      })

      this.dataPicFilter.pics = filter
    },
    handleClickTab (tab) {
      this.tabActive = tab
    },
    handleAddStore () {
      this.$store.commit('client/setTypeSidebarStore', 'add-store')
      this.showSidebar = true
      this.showTabButton = true
    },
    handleAddPicBranch () {
      this.sidebarAddPIC = true
    },
    handleCloseSidebar () {
      this.showSidebar = false
      this.sidebarAddPIC = false
      this.$store.commit('client/setClearPayloadStore')
    },
    formatDate (date) {
      return formatDateHelper({ date })
    },
    formatCurrency (value) {
      return formatCurrency(value)
    },
    getChief () {
      this.isLoadingChief = true
      const payload = {
        url: this.url,
        branch_id: this.$route.params.branchId,
        role_id: 2 // change to constant
      }

      this.$store
        .dispatch('client/getUserRoleSMRC', payload)
        .then(response => {
          const res = response.data.data
          this.dataChief = res
          this.initChief = JSON.parse(JSON.stringify(res))
          this.isLoadingChief = false
        })
        .catch(error => {
          alertErrorMessage(error)
          this.isLoadingChief = false
        })
    }
  },
  mounted () {
    setTimeout(() => {
      const query = Object.assign({}, this.$route.query)
      if (query.type) {
        delete query.type
        this.$router.replace({ query })
      }
    }, 200)
  },
  created () {
    const query = this.$route.query.type
    if (query) {
      this.tabActive = query
    }
    this.getListStore()
    this.getChief()
  },
  watch: {
    search (newValue) {
      if (newValue === '') {
        this.getListStore({ dontLoading: true })
      }
    },
    searchPic (newValue) {
      if (newValue === '') {
        this.dataPicFilter = {
          branch: this.initListStore.branch,
          pics: this.initListStore.pics
        }
      }
    }
  }
}

</script>

<style scoped>
.col-1 {
  background: linear-gradient(90deg, #ED4949 0%, #A40707 100%);
  border-radius: 20px;
  color: white;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 20px;
}

.wave-saldo {
  object-fit: cover;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 20px;
}
</style>
